<template>
  <div class="images">
    <sales-training-tour v-if="$store.state.tour & !loading" />

    <transition name="menu-popover">
      <div v-if="lightboxImage" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <h2 v-if="showCopy" class="downloadImage">
          <span class="" @click="showEula(lightboxImage, 'download')">
            <i
              class="fa fa-download fa-lg"
              aria-hidden="true"
              data-toogle="tooltip"
              title="Download a compressed version of this image"
            ></i>
          </span>
        </h2>
        <img :src="lightboxImage" @click="closeLightbox()" />
      </div>
    </transition>

    <transition name="menu-popover">
      <div v-if="lightboxVideo" class="lightbox" @click="closeLightbox()">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="lightbox-vid" v-html="lightboxVideo"></div>
        <!-- <img :src="lightboxImage" @click="closeLightbox()"/> -->
      </div>
    </transition>

    <transition name="menu-popover">
      <div v-if="lightboxConfidential" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()" style="top: 0">
          <a>&#215;</a>
        </h2>

        <iframe
          width="90%"
          height="800"
          :src="lightboxConfidential"
          frameborder="0"
          allowfullscreen
          @click="closeLightbox()"
        ></iframe>
      </div>
    </transition>

    <section
      data-v-5a90ec03
      class="pt-5 pb-3 bg8 bs-shadow mobile-top"
    >
      <div id="st-step-1" data-v-5a90ec03 class="container">
        <input
          class="top-search bs-large"
          type="text"
          placeholder="Search for sales & training media in all categories"
          v-model="searchValue"
          @keyup.enter="searchQuery"
        />
        <div class="search-btns">
          <div class="left-side">
            <h6
              v-if="search.length"
              class="mb0 d-flex"
              style="color: #fff; text-transform: uppercase"
            >
              <i class="material-icons crumb" style="margin-right: 2px"
                >category</i
              >
              Sales & Training > {{ catClean(search) }}
              {{
                fileType ==
                "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                  ? " > Documents"
                  : ""
              }}
              {{ fileType == "MPEG4" ? " > Videos" : "" }}
            </h6>
            <h6
              v-else
              class="mb0 d-flex"
              style="color: #fff; text-transform: uppercase"
            >
              <i class="material-icons crumb" style="margin-right: 2px"
                >category</i
              >
              Sales & Training > All Categories
              {{
                fileType ==
                "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                  ? " > Documents"
                  : ""
              }}
              {{ fileType == "MPEG4" ? " > Videos" : "" }}
            </h6>
          </div>
          <div class="right-side">
            <button
              v-if="searchValue.length"
              @click.prevent="searchQuery"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>

            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button
              v-if="
                searchValue.length ||
                this.search != '' ||
                this.fileType !=
                  'PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)'
              "
              @click="clearSearch"
              class="clear"
            >
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- scroll target -->
    <div id="pag"></div>

    <div :class="loading == false && !media.length ? 'message-bg' : ''">
      <div class="container">
        <div class="row row-eq-height">
          <div class="col-lg-4 col-12">
            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayFiles" class="d-flex droplink">
                <h5 id="st-step-2" style="margin-bottom: 0">Files</h5>
                <span
                  :class="showFiles == true ? 'active-tri' : 'inactive-tri'"
                  class="material-icons mlauto droplink"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showFiles" class="flexwrap mt10">
                <li v-for="all in allMedia" :key="all.title" class="mktBtns">
                  <button
                    class="btn-primary"
                    :class="{
                      active:
                        fileType ===
                        'PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)',
                    }"
                    @click="changeFile(all.search)"
                  >
                    {{ all.title }}
                  </button>
                </li>

                <li v-for="file in fileTypes" :key="file.title" class="mktBtns">
                  <button
                    class="btn-primary"
                    :class="{ active: file.search == fileType }"
                    @click="changeFile(file.search)"
                  >
                    {{ file.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayGeneral" class="d-flex droplink">
                <h5 id="st-step-3" style="margin-bottom: 0">Support</h5>
                <span
                  :class="showGeneral == true ? 'active-tri' : 'inactive-tri'"
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showGeneral" class="flexwrap mt10">
                <li
                  v-for="general in general"
                  :key="general.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: general.search == search }"
                    @click="
                      changefieldServiceCat(general.search, general.crumb)
                    "
                  >
                    {{ general.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayAD" class="d-flex droplink">
                <h5 id="st-step-3" style="margin-bottom: 0">A&amp;D CEU</h5>
                <span
                  :class="showAD == true ? 'active-tri' : 'inactive-tri'"
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showAD" class="flexwrap mt10">
                <li
                  v-for="topic in mediaContEd"
                  :key="topic.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: topic.search == search }"
                    @click="changefieldServiceCat(topic.search, topic.crumb)"
                  >
                    {{ topic.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayContracts" class="d-flex droplink">
                <h5 style="margin-bottom: 0">Contracts</h5>
                <span
                  :class="showContracts == true ? 'active-tri' : 'inactive-tri'"
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showContracts" class="flexwrap mt10">
                <li
                  v-for="contract in mediaContracts"
                  :key="contract.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: contract.search == search }"
                    @click="
                      changefieldServiceCat(contract.search, contract.crumb)
                    "
                  >
                    {{ contract.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayCET" class="d-flex droplink">
                <h5 style="margin-bottom: 0">CET</h5>
                <span
                  :class="showCET == true ? 'active-tri' : 'inactive-tri'"
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showCET" class="flexwrap mt10">
                <li
                  v-for="CET in CET"
                  :key="CET.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: CET.search == search }"
                    @click="
                      changefieldServiceCat(CET.search, CET.crumb)
                    "
                  >
                    {{ CET.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayConfigura" class="d-flex droplink">
                <h5 style="margin-bottom: 0">Configura</h5>
                <span
                  :class="showConfigura == true ? 'active-tri' : 'inactive-tri'"
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showConfigura" class="flexwrap mt10">
                <li
                  v-for="configura in configura"
                  :key="configura.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: configura.search == search }"
                    @click="
                      changefieldServiceCat(configura.search, configura.crumb)
                    "
                  >
                    {{ configura.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayUniversity" class="d-flex droplink">
                <h5 style="margin-bottom: 0">University/Training</h5>
                <span
                  :class="
                    showUniversity == true ? 'active-tri' : 'inactive-tri'
                  "
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>
              <div v-if="showUniversity">
                <ul class="flexwrap mt10">
                  <li
                    v-for="product in university"
                    :key="product.title"
                    class="mktBtns"
                  >
                    <button
                      class="btn-primary"
                      :class="{ active: product.search == search }"
                      @click="
                        changefieldServiceCat(product.search, product.crumb)
                      "
                    >
                      {{ product.title }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="card-block bs-shadow"
              style="margin-top: 15px; margin-bottom: 20px"
            >
              <div @click="displayPriceLists" class="d-flex droplink">
                <h5 style="margin-bottom: 0">Price Lists</h5>
                <span
                  :class="
                    showPriceLists == true ? 'active-tri' : 'inactive-tri'
                  "
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showPriceLists" class="mb0 mt10">
                <li
                  v-for="link in software"
                  :key="link.name"
                  class="d-block softwareli"
                >
                  <div class="d-flex">
                    <span class="lh12 software-link">
                      <a :href="link.link" target="_blank">{{
                        link.name
                      }}</a></span
                    >
                    <span class="ml-auto d-flex software">
                      <a
                        class="links mr5"
                        v-clipboard="link.link"
                        v-on:click="copyLink"
                        ><i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        ></a
                      >
                      <!--
										<a 
										:href="
												`mailto:?subject=&body=${link.link}`
										"
										class="links mr5">
										<i class="material-icons">send</i></a>
										-->
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div
            v-if="loading == true"
            class="col-xs-12 col-sm-12 col-12 col-lg-8"
          >
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
                pagination-banner
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0"><small>Searching</small></p>
              </div>

              <!-- if loading, and on desktop view -->
              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="sortView" disabled>
                  <font-awesome-icon
                    icon="sort-alpha-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
                <button v-else disabled>
                  <font-awesome-icon
                    icon="sort-alpha-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="dateView" disabled>
                  <font-awesome-icon
                    icon="sort-amount-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
                <button v-else disabled>
                  <font-awesome-icon
                    icon="sort-amount-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="listView == false" disabled>
                  <font-awesome-icon icon="list" style="margin-right: 5px" />
                  {{ " " }}View
                </button>
                <button v-else disabled>
                  <font-awesome-icon icon="th" style="margin-right: 5px" />
                  {{ " " }}View
                </button>
              </div>

              <div>
                <div id="mm-step-7" class="nav-btns">
                  <button disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button disabled>
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            <div style="margin-top: 100px">
              <div class="flex-internal">
                <atom-spinner
                  :animation-duration="1000"
                  :size="40"
                  :color="'#575757'"
                />

                <p>Loading......</p>
              </div>
            </div>
          </div>

          <div
            v-else-if="loading == false && !media.length"
            class="col-xs-12 col-sm-12 col-12 col-lg-8"
          >
            <div>
              <h2 style="margin: 30px 20px; color: #429da8; font-weight: 400">
                Sorry, I coudn't find the media that you requested...
              </h2>
              <img src="@/assets/img/userAction.svg" style="width: 100%" />
            </div>
          </div>

          <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-8">
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
                pagination-banner
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0">
                  {{ this.media.length + " " }}<small>Results</small>
                </p>
              </div>
              <!-- right side if not loading, and on desktop view-->
              <div class="d-flex align-items-center">
                <div class="view-switch switch-desktop hide-mobile">
                  <button
                    v-if="sortView"
                    @click="sortSwitch"
                    style="cursor: pointer"
                  >
                    <font-awesome-icon
                      icon="sort-alpha-up"
                      style="margin-right: 5px"
                    />
                    {{ " " }}Sort
                  </button>
                  <button
                    v-if="!sortView"
                    @click="sortSwitch"
                    style="cursor: pointer"
                  >
                    <font-awesome-icon
                      icon="sort-alpha-down"
                      style="margin-right: 5px"
                    />
                    {{ " " }}Sort
                  </button>
                </div>

                <div class="view-switch switch-desktop hide-mobile">
                  <button
                    v-if="dateView"
                    @click="dateSwitch"
                    style="cursor: pointer"
                  >
                    <font-awesome-icon
                      icon="sort-amount-up"
                      style="margin-right: 5px"
                    />
                    {{ " " }}Date
                  </button>
                  <button v-else @click="dateSwitch" style="cursor: pointer">
                    <font-awesome-icon
                      icon="sort-amount-down"
                      style="margin-right: 5px"
                    />
                    {{ " " }}Date
                  </button>
                </div>

                <div class="view-switch switch-desktop hide-mobile">
                  <button
                    v-if="listView == false"
                    @click="viewSwitch"
                    style="cursor: pointer"
                  >
                    <font-awesome-icon
                      icon="list"
                      style="margin-right: 5px"
                    />{{ " " }}View
                  </button>
                  <button v-else @click="viewSwitch" style="cursor: pointer">
                    <font-awesome-icon icon="th" style="margin-right: 5px" />{{
                      " "
                    }}View
                  </button>
                </div>

                <div>
                  <div id="st-step-6" class="nav-btns">
                    <button v-if="this.offsetVal == 0" disabled>
                      <i class="material-icons">keyboard_arrow_left</i> Back
                    </button>
                    <button v-else @click="offsetValMinus()">
                      <i class="material-icons">keyboard_arrow_left</i> Back
                    </button>

                    <button v-if="this.media.length < 99" disabled>
                      Next
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                    <button v-else @click="offsetValPlus()">
                      Next
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- end right -->
            </div>

            <!-- ###################### if grid view ######################### -->
            <div class="row" v-if="!listView">
              <div
                v-for="(el, index) in media"
                :key="el.external_id"
                class="col-md-4 col col-sm-6 col-xs-12 col-12"
              >
                <div
                  class="card bs-shadow br4 mb15"
                  style="margin-bottom: 15px"
                >
                  <!-- pdf image -->
                  <div
                    :class="
                      el.file_properties['format_type'] == 'compressed_archive'
                        ? 'zip'
                        : null
                    "
                    class="img-responsive-4by3"
                    v-bind:style="{
                      backgroundImage:
                        'url(' + el.thumbnails['600px'].url + ')',
                    }"
                    style="background-size: cover"
                  >
                    <span
                      :id="index === 0 ? 'st-step-4' : ''"
                      class="file-icon"
                      v-if="!listView"
                    >
                      <i
                        v-if="
                          el.file_properties['format_type'] == 'pdf' &&
                          !listView
                        "
                        class="fas fa-file-pdf"
                      ></i>
                      <i
                        v-else-if="
                          el.file_properties['format_type'] == 'image' &&
                          !listView
                        "
                        class="far fa-image"
                      ></i>
                      <i
                        v-else-if="
                          el.file_properties['format_type'] == 'video' &&
                          !listView
                        "
                        class="fas fa-video"
                      ></i>
                      <i
                        v-else-if="
                          el.file_properties['format_type'] == 'office' &&
                          !listView
                        "
                        class="fas fa-file"
                      ></i>
                      <i
                        v-else-if="
                          el.file_properties['format_type'] ==
                            'compressed_archive' && !listView || el.file_properties['format'] == 'PdfArchive' && !listView
                        "
                        class="fas fa-file-archive"
                      ></i>
                    </span>
                  </div>

                  <div :id="index === 0 ? 'st-step-5' : ''" class="card-name">
                    <p class="media-title" style="margin-bottom: 5px">
                      <span
                        v-if="
                          el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                        "
                        class="badge badge-orange"
                        style="margin-right: 5px; font-size: inherit"
                        >CONFIDENTIAL</span
                      >{{ el.filename }}
                    </p>

                    <!-- ###################### if it's an image (images currently disabled) ######################### -->
                    <!--
                    <span
                      class="button-wrap"
                      v-if="el.file_properties['format_type'] == 'image'"
                    >
                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                        "
                        class="first links"
                        v-on:click="showLightbox(el.thumbnails['600px'].url)"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this image"
                          >visibility</i
                        >
                      </a>
                      <a
                        v-else
                        class="first links"
                        v-on:click="showLightbox(el.thumbnails['600px'].url)"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this image"
                          >visibility</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        :href="el.embeds['original'].url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          class="material-icons"
                          aria-hidden="true"
                          data-toogle="tooltip"
                          title="Download this image"
                        >
                          sim_card_download</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['original'].share"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </span>
-->
                    <!-- ###################### END Image ######################### -->
                    <!-- ###################### if it's a document ######################### -->

                    <span
                      class="button-wrap"
                      v-if="
                        (el.file_properties['format_type'] == 'pdf') |
                          (el.file_properties['format_type'] == 'office') &&
                        !listView
                      "
                    >
                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                        "
                        @click="
                          showEula(
                            el.embeds['document_viewer_with_download'].url,
                            'download'
                          )
                        "
                        class="first links"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this PDF"
                          >visibility</i
                        >
                      </a>

                      <a
                        v-else
                        class="first links"
                        @click="
                          showLightboxConf(
                            el.embeds['document_html5_viewer'].url
                          )
                        "
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this PDF"
                          >visibility</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        :href="el.embeds['original'].share"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          class="material-icons"
                          aria-hidden="true"
                          data-toogle="tooltip"
                          title="Download this Document"
                        >
                          sim_card_download</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['original'].share"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </span>

                    <!-- ###################### END document ######################### -->
                    <!-- ###################### if it's a video ######################### -->

                    <span
                      class="button-wrap"
                      v-if="
                        el.file_properties['format_type'] == 'video' &&
                        !listView
                      "
                    >
                      <a
                        class="first links"
                        v-on:click="
                          showLightboxVideo(el.embeds['video_player'].html)
                        "
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this video"
                          >visibility</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['video_player'].url"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        @click="showEula(el.embeds['video_player'].url, 'mail')"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </span>
                    <!-- ###################### end video ######################### -->

                    <!-- ###################### if it's a zip ######################### -->

                    <span
                      class="button-wrap"
                      v-if="
                        el.file_properties['format_type'] ==
                          'compressed_archive' && !listView || el.file_properties['format'] == 'PdfArchive' && !listView
                      "
                      style="
                        -webkit-align-content: flex-end;
                        align-content: flex-end;
                      "
                    >
                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                        "
                        class="links ml-auto mr5"
                        :href="el.embeds['original'].share"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          class="material-icons"
                          aria-hidden="true"
                          data-toogle="tooltip"
                          title="Download this zip archive"
                        >
                          sim_card_download</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['original'].share"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </span>
                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- #################### end grid view #################### -->
            </div>

            <div class="row" v-else-if="listView">
              <!-- #################### if list view #################### -->
              <div
                v-for="(el, index) in media"
                :key="el.external_id"
                class="col-xs-12 col-12 list-view"
              >
                <div class="card bs-shadow br4 mb8" style="margin-bottom: 8px">
                  <div
                    :id="index === 0 ? 'st-step-5' : ''"
                    class="card-name"
                    style="display: flex; padding: 10px"
                  >
                    <p class="mrauto mb0" style="max-width: 70%">
                      <span
                        v-if="
                          el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                        "
                        class="badge badge-orange"
                        style="margin-right: 5px; font-size: inherit"
                        >CONFIDENTIAL</span
                      ><span>{{ el.filename }}</span>
                    </p>

                    <div
                      class="button-wrap"
                      v-if="
                        el.file_properties['format_type'] == 'image' && listView
                      "
                    >
                      <a
                        class="links mr5"
                        v-on:click="showLightbox(el.thumbnails['600px'].url)"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this image"
                          >visibility</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        :href="el.embeds['original'].url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          class="material-icons"
                          aria-hidden="true"
                          data-toogle="tooltip"
                          title="Download this image"
                        >
                          sim_card_download</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['original'].share"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </div>

                    <div
                      class="button-wrap"
                      v-if="
                        (el.file_properties['format_type'] == 'pdf') |
                          (el.file_properties['format_type'] == 'office') &&
                        listView
                      "
                    >
                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                        "
                        @click="
                          showEula(
                            el.embeds['document_viewer_with_download'].url,
                            'download'
                          )
                        "
                        target="_blank"
                        class="mr5 links"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this PDF"
                          >visibility</i
                        >
                      </a>
                      <a
                        v-else
                        class="mr5 links"
                        @click="
                          showLightboxConf(
                            el.embeds['document_html5_viewer'].url
                          )
                        "
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this PDF"
                          >visibility</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        :href="el.embeds['original'].share"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          class="material-icons"
                          aria-hidden="true"
                          data-toogle="tooltip"
                          title="Download this Document"
                        >
                          sim_card_download</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['original'].share"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </div>

                    <div
                      class="button-wrap"
                      v-if="el.file_properties['format_type'] == 'video'"
                    >
                      <a
                        class="links mr5"
                        v-on:click="
                          showLightboxVideo(el.embeds['video_player'].html)
                        "
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Preview this video"
                          >visibility</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['video_player'].url"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        :href="`mailto:?subject=Link&body=${el.embeds['video_player'].url}`"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </div>

                    <div
                      class="button-wrap"
                      v-if="
                        el.file_properties['format_type'] ==
                        'compressed_archive' || el.file_properties['format'] == 'PdfArchive'
                      "
                    >
                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                        "
                        class="links mr5 ml-auto"
                        :href="el.embeds['original'].share"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          class="material-icons"
                          aria-hidden="true"
                          data-toogle="tooltip"
                          title="Download this zip archive"
                        >
                          sim_card_download</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links mr5"
                        v-clipboard="el.embeds['original'].share"
                        v-on:click="copyLink"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        >
                      </a>

                      <a
                        v-if="
                          !el.security.asset_groups.includes(
                            'Confidential Documents'
                          ) && showCopy
                        "
                        class="links"
                        :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                      >
                        <i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Email this file"
                          >send</i
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- #################### Bottom pagination #################### -->
            <div
              v-if="media.length >= 4"
              class="
                banner
                d-flex
                align-items-center
                p-3
                mb1r
                rounded
                bs-shadow
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0">
                  {{ this.media.length + " " }}<small>Results</small>
                </p>
              </div>

              <div>
                <div id="st-step-6" class="nav-btns">
                  <button v-if="this.offsetVal == 0" disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>
                  <button v-else @click="offsetValMinus()">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-if="this.media.length < 99" disabled>
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                  <button v-else @click="offsetValPlus()">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            <!-- end bottom pagination -->
          </div>
          <!-- end other column -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Eula from "../shared/Eula.js";
import SalesTrainingTour from "../components/Tours/SalesTrainingTour";
import VueDocPreview from "vue-doc-preview";

//old auth with admin priveleges
//headers: {Authorization:'Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6'}

const auth = {
  headers: {
    Authorization: "Bearer spacesaver/a59d39c4db9c288752c13e9c1196c9cf",
  },
};

export default {
  name: "SalesTraining",
  components: {
    AtomSpinner,
    SalesTrainingTour,
    VueDocPreview,
  },
  data() {
    return {
      pageSlug: "",
      msg: "",
      offsetVal: 0,
      search: this.$route.query.cat ? this.$route.query.cat : "",
      searchValue: this.$route.query.search ? this.$route.query.search : "",
      media: [],
      loading: true,
      imagesInPresentation: [],
      selectedImage: "",
      selectedVid: null,
      fileType:
      "PDF}%20or%20{PDF Archive}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
      //fileType: "PDF}%20or%20{MPEG4}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
      lightboxImage: "",
      lightboxVideo: "",
      lightboxConfidential: "",
      allMedia: [
        {
          title: "All Media",
          search:
            "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
          active: false,
        },
      ],
      images: [
        { id: 1, name: "http://www.google.com/image1" },
        { id: 2, name: "ttp://www.google.com/image2" },
        { id: 3, name: "ttp://www.google.com/image3" },
      ],
      catList: [],
      catCrumb: "",
      showFiles: true,
      showContracts: true,
      showGeneral: true,
      showAD: true,
      showCET: true,
      showConfigura: true,
      showUniversity: true,
      //cat: 'ActivStor',
      cat: "Sales%20and%20Training%20Support",
      showPriceLists: true,
      software: [
        {
          name: "Commercial Price List",
          link: "https://ordermanage.spacesaver.com/salesdocs/salesdocs/Commercial%20Price%20List.pdf",
        },
        {
          name: "Service Price List",
          link: "https://ordermanage.spacesaver.com/salesdocs/salesdocs/Service%20Price%20List.pdf",
        },
      ],
      eulaAccepted: false,
      searchQ: this.$route.query.search ? this.$route.query.search : null,
      listView: false,
      sortView: false,
      dateView: false,
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "media");

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        let view = localStorage.getItem("SSCview");
        if (view == "list") {
          this.listView = true;
        } else {
          this.listView = false;
        }
        this.loading = false;
      });
    },
    catClean: function (cat) {
      console.log(cat);
      //remove everything before the last /
      let a = cat.replace(/%20/g, " ").split("/").pop();
      return a;
    },

    crumbClean: function (cat) {
      let a = cat
        .replace("Sales%20and%20Training%20Support/", " > ")
        .replace(/%20/g, " ")
        .replace("/", " ");
      console.log(a);
    },

    viewSwitch: function () {
      this.listView = !this.listView;
      if (this.listView) {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "list");
      } else {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "grid");
      }
    },

    sortSwitch: function () {
      if (this.sortView == false) {
        this.media.sort((a, b) => (a.filename > b.filename ? 1 : -1));
      } else {
        this.media.sort((a, b) => (a.filename < b.filename ? 1 : -1));
      }
      this.sortView = !this.sortView;
    },

    dateSwitch: function () {
      if (this.dateView == false) {
        this.media.sort((a, b) =>
          a.last_update_date > b.last_update_date ? 1 : -1
        );
      } else {
        this.media.sort((a, b) =>
          a.last_update_date < b.last_update_date ? 1 : -1
        );
      }
      this.dateView = !this.dateView;
    },

    copyLink: function () {
      this.$noty.success("Successfully copied link to clipboard!");
    },

    copyData(link) {
      this.makeData();
      this.$clipboard(this.invite_code);
      alert("Copied to clipboard");
    },

    addImageToPresentation(name) {
      let image = {
        thumb: name,
        large: "large image",
      };
      this.$store.commit("addToImages", image);
      this.$noty.success("Successfully added image to presentation!");
      //this.$noty.error("Oooops! Something went wrong");
    },

    offsetValMinus() {
      this.offsetVal -= 100;
      this.loading = true;
      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    offsetValPlus() {
      this.offsetVal += 100;
      this.loading = true;
      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    changefieldServiceCat(name, crumb) {
      this.offsetVal = 0;
      this.loading = true;
      this.searchValue = "";
      //this.$store.commit('changeMarket', name);

      this.search = name;
      this.catCrumb = crumb;
      //this.cat = name;
      if (
        this.fileType ==
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState({}, "", `/sales-and-training?cat=${this.search}`);
      } else if (this.fileType == "JPEG") {
        history.pushState(
          {},
          "",
          `/sales-and-training?cat=${this.search}&type=image`
        );
      } else if (this.fileType == "MPEG4") {
        history.pushState(
          {},
          "",
          `/sales-and-training?cat=${this.search}&type=video`
        );
      } else if (
        this.fileType ==
        "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/sales-and-training?cat=${this.search}&type=document`
        );
      } else {
        history.pushState({}, "", `/sales-and-training?cat=${this.search}`);
      }
      /*
			if(this.$route.query.search){
				history.pushState({}, '', `/sales-and-training`);
			}
			*/

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.media.sort((a, b) =>
          a.last_update_date < b.last_update_date ? 1 : -1
        );
        this.loading = false;
        //const el = document.getElementById("pag");
        //el.scrollIntoView({ behavior: "smooth" });
      });

      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items));
      //this.loading = false;
    },

    changeFile(name) {
      this.offsetVal = 0;
      this.loading = true;
      this.fileType = name;

      if (
        this.fileType ==
          "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length
      ) {
        history.pushState({}, "", `/sales-and-training?cat=${this.search}`);
      } else if (
        this.fileType ==
          "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.searchValue.length
      ) {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}`
        );
      } else if (this.fileType == "JPEG" && this.search.length) {
        history.pushState(
          {},
          "",
          `/sales-and-training?cat=${this.search}&type=image`
        );
      } else if (this.fileType == "JPEG" && this.searchValue.length) {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}&type=image`
        );
      } else if (this.fileType == "MPEG4" && this.search.length) {
        history.pushState(
          {},
          "",
          `/sales-and-training?cat=${this.search}&type=video`
        );
      } else if (this.fileType == "MPEG4" && this.searchValue.length) {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}&type=video`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length
      ) {
        history.pushState(
          {},
          "",
          `/sales-and-training?cat=${this.search}&type=document`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.searchValue.length
      ) {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}&type=document`
        );
      } else {
        history.pushState({}, "", `/sales-and-training`);
      }

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
        //const el = document.getElementById("pag");
        //el.scrollIntoView({ behavior: "smooth" });
      });

      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items));
      //this.loading = false;
    },

    clearSearch: function () {
      //this.searchStarted = false;
      this.searchValue = "";
      this.search = "";
      this.cat = "Sales%20and%20Training%20Support";
      this.fileType =
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
      this.loading = true;
      history.pushState({}, "", `/sales-and-training`);
      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    searchQuery(search) {
      this.search = "";
      this.offsetVal = 0;
      this.loading = true;

      if (
        this.fileType ==
        "PDF}%20or%20{MPEG4}%20or%20{ZIP}%20or{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}`
        );
      } else if (this.fileType == "JPEG") {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}&type=image`
        );
      } else if (this.fileType == "MPEG4") {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}&type=video`
        );
      } else if (
        this.fileType ==
        "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}&type=document`
        );
      } else {
        history.pushState(
          {},
          "",
          `/sales-and-training?search=${this.searchValue}`
        );
      }

      return (
        axios
          /*
        .get(
          `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
          auth
        )
        */
          //https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3AService%20and%20Installation%2F${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails
          .get(this.endPointCompute, auth)
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
            //const el = document.getElementById("pag");
            //el.scrollIntoView({ behavior: "smooth" });
          })
      );
    },

    showLightbox(media) {
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "preview");
        //this.lightboxImage = media;
      } else {
        this.lightboxImage = media;
      }
    },
    showLightboxVideo(media) {
      let file = media;
      console.log(media);
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "confvid");
        //this.lightboxVideo = file;
      } else {
        //this.$store.commit("UPDATE_LIGHTBOX_VIDEO_FIELD_SERVICE", media);
        this.lightboxVideo = media;
      }
    },
    showLightboxConf(media) {
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "confdoc");
        //this.lightboxConfidential = media;
      } else {
        this.lightboxConfidential = media;
      }
      /*
			document.addEventListener('contextmenu', function(event) {
			event.preventDefault();
			}, true); 
			*/
    },

    closeLightbox() {
      this.lightboxImage = "";
      this.lightboxVideo = "";
      this.lightboxConfidential = "";
      //document.removeEventListener("contextmenu");
    },

    isActiveButton: function (text) {
      return text;
    },

    displayFiles() {
      this.showFiles = !this.showFiles;
    },
    displayContracts() {
      this.showContracts = !this.showContracts;
    },
    displayGeneral() {
      this.showGeneral = !this.showGeneral;
    },
    displayAD() {
      this.showAD = !this.showAD;
    },
    displayCET() {
      this.showCET = !this.showCET;
    },
    displayConfigura() {
      this.showConfigura = !this.showConfigura;
    },
    displayUniversity() {
      this.showUniversity = !this.showUniversity;
    },
    displayPriceLists() {
      this.showPriceLists = !this.showPriceLists;
    },
    /*
    showCats() {
      // https://api.widencollective.com/v2/categories/{category}/{subcategory}/{...}
    }
    */
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    shouldButtonBeActive: function () {
      return (
        this.fieldServiceCats.filter((el) => this.isActiveButton(el)).length ===
        0
      );
    },

    myOffsetVal() {
      return this.offsetVal.valueOf();
    },

    endPointCompute() {
      if (this.searchValue.length) {
        //return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=99&offset=${this.offsetVal}&search_document_text=true&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`;
        return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3A${this.cat}%20${this.searchValue}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
      } else {
        //return `https://api.widencollective.com/v2/assets/search?query=cat%3A${this.cat}%2f${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
        return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3A${this.cat}%2f${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
      }
    },

    //https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3AService%20and%20Installation%2F${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails
    cart() {
      return this.$store.state.cart;
    },

    currentMarket() {
      return this.$store.state.fieldServiceCat;
    },

    mediaContracts() {
      return this.$store.state.mediaContracts;
    },
    fileTypes() {
      return this.$store.state.limitedFileTypes;
    },
    general() {
      return this.$store.state.mediaGeneral;
    },
    CET() {
      return this.$store.state.mediaCET;
    },
    configura() {
      return this.$store.state.mediaConfigura;
    },
    university() {
      return this.$store.state.university;
    },
    mediaContEd() {
      return this.$store.state.mediaContEd;
    },
    activeRAC() {
      return this.$store.state.fieldServiceActivRAC;
    },
    showCopy() {
      return this.$store.state.eulaAccepted;
    },
  },
  created() {
    this.$store.commit("showNavigation");
    this.showEula = Eula.showEula; // now you can call this.showEula() (in your functions/template)
    this.triggerLightbox = Eula.triggerLightbox;
    /*
    if (this.$route.params.slug) {
      history.pushState({}, '', `/pagepath/path?query=${this.myQueryParam}`);
      this.this.pageSlug = this.$route.params.slug;
    }
    */
    // console.log(this.$route.path);
  },

  mounted() {
    if (this.$route.query.type == "document") {
      this.fileType =
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
    } else if (this.$route.query.type == "image") {
      this.fileType == "JPEG";
    } else if (this.$route.query.type == "video") {
      this.fileType == "MPEG4";
    } else {
      this.fileType ==
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
    }
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.docwrap {
  background-repeat: no-repeat;
  background-position: center center;
}
.docwrap img {
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.card {
  margin-bottom: 15px;
}
.word-wrap {
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadImage a {
  color: #fff;
  transition: color 0.5s ease;
}
.downloadImage a:hover {
  color: #42b983;
}
.mr5 {
  margin-right: 5px;
}
.img-responsive-4by3 {
  display: block;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
}
.img-responsive-4by3 img {
  width: 100%;
}
.img-responsive-16by9 {
  display: block;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.img-responsive-16by9 img {
  width: 100%;
}
span.file-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  line-height: 1;
  border-radius: 4px;
}
span.file-icon-list {
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  line-height: 1;
  border-radius: 4px;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  border-color: transparent #000000 transparent transparent;
  line-height: 0px;
  _border-color: #ffffff #000000 #ffffff #ffffff;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
}
.flexwrap {
  margin-bottom: 0;
}

.software {
  align-items: center;
  justify-content: center;
}
.software .material-icons {
  font-size: 1.2em !important;
}

.softwareli {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  margin: 0 0 5px;
}
.lh12 {
  line-height: 1.2;
}
.software-link a {
  color: #429da8;
  cursor: pointer;
}

.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  flex: 1;
  height: 40px;
}
.inactive-tri {
  /*transition: transform 0.2s ease-in-out;*/
  transition: transform 0.4s ease-in-out;
  transform-style: preserve-3d;
}
.active-tri {
  transition: transform 0.4s ease-in-out;
  display: flex;
  align-items: flex-end;
  align-items: flex-end;
  /*transform: rotate(180deg);*/
  transform: rotateX(180deg);
  display: flex;
  align-items: center;
}
.badge-orange {
  background-color: #00b4b4;

  background-color: #3e4651;
  background-color: #f16232;
  color: #fff;
  font-size: 0.7em !important;
  font-weight: 500;
}
.list-view:last-of-type {
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
}
</style>
